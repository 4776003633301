<template>
  <div class="vh-100">
    <sidebar/>
    <div class="d-flex flex-column">
      <navbar/>
      <keep-alive>
        <router-view class="flex-fill"></router-view>
      </keep-alive>
    </div>
    
  </div>
</template>

<script>

import Navbar from '@/components/base/Navbar.vue'
import Sidebar from '@/components/base/Sidebar.vue'

export default {
  components:{
    Navbar,
    Sidebar,
  }
}
</script>
